* {
  box-sizing: border-box;
}

html {
  font-size: 16px;
}

body {
  background-color: #252839;
  font-family: 'Open Sans', 'Helvetica', 'Arial', sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: normal;
}

img {
  display: inline-block;
  max-width: 100%;
  height: auto;
}
